import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";


const Logo = ({image, footer, ifinmheader}) => {

    const styles = {
        marginTop: '',
        marginLeft: footer? '-85px':`${ifinmheader? '-40px':'-70px'}`,
        minWidth: footer? '400px':'280px',
        padding: ifinmheader? '0px 0px':''
    };

    return(
        <div className="header-logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img style={styles} className="dark-logo" src={process.env.PUBLIC_URL + image} alt="Agency Logo" />
            </Link>
        </div>
    )
}

Logo.propTypes = {
    image: PropTypes.string,
    footer: PropTypes.bool,
    ifinmheader: PropTypes.bool
};

export default Logo;
