import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import P5Wrapper from 'react-p5-wrapper';
import sketch from '../../sketch'
import "../../assets/scss/_HomeFade.scss";

const Auto = () => {

    const matches = useMediaQuery('(min-width:1080px)');

    const stylesbackground = {
        backgroundImage: 'url(imgs/bannerm.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '56.3vw',
        zIndex: '9'
     };

    const head = {
        fontFamily: 'Open Sans',
        fontSize: matches? '4vw':'3vh',
        fontWeight: '800',
        color: '#fff',
        marginBottom: '2vh',
        textAlign: 'left',
        lineHeight: '1'
    }

    const headwrap = {
        position: 'absolute',
        left: '10%',
        top: matches? '15%':'10%',
        zIndex: '10'
    }

    return (
        <React.Fragment>
          <div style={{display: matches? 'none':'inherit', height: '85px', backgroundColor: '#1e1e1e'}} />
          <div style={headwrap}>
            <div className="floating">
                <h1 style={head}>
                Folie ist die<br/>Inspiration<br/>von Heute
                </h1>
            </div> 
          </div>
          <div id="bglambo" style={stylesbackground}/>
          <>
            <P5Wrapper sketch={sketch}/>
          </>
        </React.Fragment>
    )
}

export default Auto;