import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "../../assets/scss/_HomeFade.scss";

export default function Description2() {

    const matchesTB = useMediaQuery('(max-width:1200px)');
    const matchesTB2 = useMediaQuery('(min-width:600px)');
    const path = "/imgs/icons2/"

    const styles = {
        fontSize: matchesTB? '13px':'18px'
    }

    const container = {
        display: 'flex',
        flexDirection: matchesTB? (matchesTB2? 'row': 'column') :'row',
        justifyContent: 'space-evenly',
        margin: '3vw auto',
        width: '90%'
    }

    return (
        <div style={{padding: '6vh 0 3vh 0'}}>

            <div style={container}>

                <a className="textbox">

                    <h1 className="headline">Preis</h1>
                    <p style={styles}>Die Preise der Folierungen variieren stark:<br/> Laufmeter, Gr&ouml;ße des Fahrzeugs, sowie Folienkosten lassen somit nicht genau bestimmen. In der Regel ist eine Folierung 20-40% g&uuml;nstiger als eine Lackierung. </p>
                    <div className="icon-p" style={{margin: '0px auto'}}><img className="icovi" src={path+"kosten-1.png"} alt="service" /></div>
                </a>

                <a className="textbox">

                    <h1 className="headline">Vorteile</h1>
                    <ul style={{listStyle: 'none'}}>
                        <li style={styles} data-aos="fade-up">g&uuml;nstiger und schneller als eine Lackierung.</li>
                        <li style={styles} data-aos="fade-up">Schützt den ursprünglichen Lack.</li>
                        <li style={styles} data-aos="fade-up">Unendliche Auswahl an Farben und Designs.</li>
                    </ul>
                    <div className="icon-v" style={{margin: '0px auto'}}><img className="icovi" src={path+"vorteile-1.png"} alt="service" /></div>
                </a>

                <a className="textbox">

                    <h1 className="headline">Unser Service</h1>
                    <ul style={{listStyle: 'none'}}>
                        <li style={styles} data-aos="fade-up">Beratung durch Experten f&uuml;r mobiles Marketing.</li>
                        <li style={styles} data-aos="fade-up">Gestaltung durch unser Grafikteam.</li>
                        <li style={styles} data-aos="fade-up">Fertigung im Haus.</li>
                        <li style={styles} data-aos="fade-up">Montage durch Fachpersonal.</li>
                    </ul>
        	        <div className="icon-s" style={{margin: '0px auto'}}><img className="icovi" src={path+"icon-service-1.png"} alt="service" /></div>
                </a>

            </div>


            {/* <div style={container}>

                <a className="textbox">

                    <h1 className="headline">Voll-Folierung</h1>
                    <p>Von außen werden alle sichtbaren Flächen foliert. Je nach Wunsch können auch Leisten im Innenraum und Türkanten überzogen werden.</p>

                </a>

                <a className="textbox">

                    <h1 className="headline">Teil-Folierung</h1>
                    <p>Dabei werden nur einzelne Muster oder Flächen foliert.</p>

                </a>

                <a className="textbox">

                    <h1 className="headline">Werbe-Folierung</h1>
                    <p>Wir erstellen eine individuelle Beschriftung mit Ihrem Firmenlogo. Diese wird perfekt auf Ihrem Fahrzeug angebracht um das nötige Aufsehen zu erregen.</p>

                </a>

            </div> */}
                
        </div>
    )
}
