import React from 'react'
import { useState, useEffect} from "react";
import { Link} from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "../../assets/scss/_HomeFade.scss";

export default function HomeFade() {
    
    // const iconheight = -((0.7*0.25*screen.width)-((0.1*screen.height)));
    const matchesPC = useMediaQuery('(min-width:1345px)');
    const matchesTPC = useMediaQuery('(min-width:1080px)');
    const [bottom, setBottom] = useState(false);
    const inuse = 0.7285*screen.width + 600;

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, {passive: true} );
        return () => {
        window.removeEventListener("scroll", handleScroll, {passive: true});
        };

    }, []);

    const handleScroll = () => {     
        if(window.pageYOffset>inuse+500) setBottom(true);
    };

    const styles = {
        backgroundSize: 'cover',
        height: (screen.width*9/16)+'px',
        width: '100vw'
        //position: window.pageYOffset<7350? 'sticky' : 'static',
    }

    const styles2 = {

        backgroundSize: 'cover',
        //position: window.pageYOffset<7350? 'sticky' : 'static',
        zIndex: '2',
        alignSelf: 'flex-start',
        height: (screen.width*9/16)+'px',
        width: '100vw',
    }

    const head = {
        fontSize: matchesPC? '1.4vw':'1.3vh',
        padding: matchesPC? '15px 15px':'4px 8px',
        zIndex: '99'
    }

    return (
        <React.Fragment>
            <div  id="carpoints1" className="carcontainer">
                <img style={{position: 'absolute'}} src="imgs/h1-backround-2png.png"/>
                <div style={styles2}>
                <img style={{position: 'absolute'}} src="imgs/bannerfade2.png"/>
                    <div style={styles} className="ehre">
                    <img style={{position: 'absolute'}} src="imgs/parts/backblock.png"/>
                        <img style={{position: 'absolute'}} src='/imgs/car2-blau.png' /*className='glitch'*/ />
                            <div style={{transform: `scaleY(${matchesTPC? 0.8:1})`, paddingTop: matchesTPC? '5%':''}}>
                                <div style={{opacity: (window.pageYOffset>inuse||bottom)? '1': '0', transition: 'opacity 2s', position: 'absolute', width: '100vw'}}>
                                    <div style={{zIndex: '1', left: '53%', top: '7.5%', position: 'absolute'}}><Link to={process.env.PUBLIC_URL + "/teil"}><span className="menu-text myButton" style={head}><strong style={{color: '#fff'}}>Lackschutz-Folierung</strong></span></Link></div>
                                    <img style={{zIndex: '1'}} src='/imgs/parts/block1.png' /*className='glitch'*/ />
                                </div>
                                <div style={{opacity: (((window.pageYOffset>inuse+150))||bottom)? '1': '0', transition: 'opacity 2s', position: 'absolute', width: '100vw'}}>
                                    <div style={{zIndex: '1', left: '59.6%', top: '27%', position: 'absolute'}}><Link to={process.env.PUBLIC_URL + "/scheiben"}><span className="menu-text myButton" style={head}><strong style={{color: '#fff'}}>Scheiben-Folierung</strong></span></Link></div>
                                    <img style={{zIndex: '1'}} src='/imgs/parts/block2.png' /*className='glitch'*/ />
                                </div>   
                                <div style={{opacity: ((window.pageYOffset>inuse+300)||bottom)? '1': '0', transition: 'opacity 2s', position: 'absolute', width: '100vw'}}>
                                    <div style={{zIndex: '1', left: '53%', top: '46.5%', position: 'absolute'}}><Link to={process.env.PUBLIC_URL + "/voll"}><span className="menu-text myButton" style={head}><strong style={{color: '#fff'}}>Voll-Folierung</strong></span></Link></div>
                                    <img style={{zIndex: '1'}} src='/imgs/parts/block3.png' /*className='glitch'*/ />
                                </div>
                                <div style={{opacity: ((window.pageYOffset>inuse+400)||bottom)? '1': '0', transition: 'opacity 2s', position: 'absolute', width: '100vw'}}>
                                    <div style={{zIndex: '1', left: '59.6%', top: '67%', position: 'absolute'}}><Link to={process.env.PUBLIC_URL + "/teil"}><span className="menu-text myButton" style={head}><strong style={{color: '#fff'}}>Teil-Folierung</strong></span></Link></div>
                                    <img style={{zIndex: '1'}} src='/imgs/parts/block4.png' /*className='glitch'*/ />
                                </div>  
                                <div style={{opacity: ((window.pageYOffset>inuse+500)||bottom)? '1': '0', transition: 'opacity 2s', position: 'absolute', width: '100vw'}}>
                                    <div style={{zIndex: '1', left: '53%', top: '86%', position: 'absolute'}}><Link to={process.env.PUBLIC_URL + "/werbe"}><span className="menu-text myButton" style={head}><strong style={{color: '#fff'}}>Werbe-Folierung</strong></span></Link></div>
                                    <img style={{zIndex: '1'}} src='/imgs/parts/block5.png' /*className='glitch'*/ />
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>



                
        </React.Fragment>
    )
}