import React from 'react'
import {NavLink} from 'react-router-dom';
import PropTypes from "prop-types"
import Logo from '../../components/logo/Logo';

const NavBar = ({colo}) => {

    const styles = {
        color: colo,
        fontFamily: 'Inter',
        fontWeight: 'bold',
        margin: '0px auto',
        textAlign: 'center',
        width: '80%'
    }

    const substyles = {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        textAlign: 'left'
    }

    const styleitems = {
        width: '20%',
        
    }

    return (
        <nav className="site-main-menu">
            
            <ul style={styles}>                
                <li style={styleitems} className="has-children icons2">
                    <NavLink to={process.env.PUBLIC_URL + "/voll"}><span style={styles} className="menu-text">Services</span></NavLink>
                    <div className="menu-toggle" style={styles}><i className="far fa-angle-down"></i></div>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/voll"}><span style={substyles} className="menu-text">Voll-Folierung</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/teil"}><span style={substyles} className="menu-text">Teil-Folierung/<br/>Lackschutz-Folierung</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/werbe"}><span style={substyles} className="menu-text">Werbe-Folierung</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/scheiben"}><span style={substyles} className="menu-text">Scheibent&ouml;nung</span></NavLink></li>
                    </ul>
                </li>
                <li style={styleitems} className="has-children icons2">
                    <NavLink to={process.env.PUBLIC_URL + "/haus"}><div style={styles} className="menu-text">Geb&auml;udefolierung</div></NavLink>
                    <div className="menu-toggle" style={styles}><i className="far fa-angle-down"></i></div>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/haus"}><span style={substyles} className="menu-text">Werbe-Folierung/<br/>Fenster-Folierung</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/sonnenschutz"}><span style={substyles} className="menu-text">Sonnenschutz-Folierung/<br/>Sichtschutz-Folierung</span></NavLink></li>
                    </ul>
                </li>
                <li style={styleitems} className="icons">
                    <Logo 
                        image={`${process.env.PUBLIC_URL}/imgs/folientechnik${colo=='#000'? 's' : 'w'}.png`}
                    />
                </li>
                <li style={styleitems} className="icons2">
                    <NavLink to={process.env.PUBLIC_URL + "/about"}><div style={styles} className="menu-text">&Uuml;ber uns</div></NavLink>
                </li>
                <li style={styleitems} className="icons2">
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><div style={styles} className="menu-text">Kontakt</div></NavLink>
                </li>
            </ul>
        </nav>
    )
}

NavBar.propTypes = {
    colo: PropTypes.string
}


export default NavBar
