import React from 'react'
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
// import section from '../components/SectionTitles/SectionTitle';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link} from 'react-router-dom';
import {useEffect, useState} from "react";
import Description3 from '../container/Description/Description3';
// import PictureBox from '../container/About/PictureBox';
import "../assets/scss/_HomeFade.scss";

const Sonnenschutz = () => {

    const matchesPC = useMediaQuery('(min-width:1200px)');

    // const path = 'imgs/about/FOTO';
    
    const height = screen.width*0.8*9/16;

    const offset = matchesPC? 0 : 85;

    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    // const matchesPC = useMediaQuery('(min-width:550px)');
    const matchesTB = useMediaQuery('(max-width:700px)');

    const styles = {
        display: 'block',
        justifyContent: 'space-evenly'
        //marginLeft: '25%'
    }

    const head = {
        fontFamily: 'Archivo Black',
        fontSize: '5vw',
        fontWeight: '1000',
        color: '#fff',
        marginBottom: '2vh',
        lineHeight: '1.1'
    }

    const headwrap = {
        display: matchesPC? 'inherit':'none',
        position: 'absolute',
        zIndex: '1',
        marginLeft: '10%',
        marginTop: '7%',
    }

    const descriptionhead = {
        fontSize: matchesTB? '3vh':'2.5vw',
        textAlign: 'center'
    }

    const text = {
        textAlign: 'left',
        fontSize: matchesTB? '2vh':'1.1vw',
        marginTop: '3vh',
        marginBottom: '5vh',
        padding: '2% 0'
    }

    const textcontainer = {
        width: '88%',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0px 5px 83px 0px rgba(9, 29, 62, 0.15)',
        borderRadius: '12px',
        marginTop: '-9vw',
        zIndex: '2',
        position: 'relative',
        padding: '0px 10% 40px'
    }

    return (
        <React.Fragment>
            <img style={{position: 'absolute', top: '0'}} src="imgs/h1-backround-1png.png"/>
            <script
                defer
                src="https://unpkg.com/img-comparison-slider@4/dist/index.js"
            ></script>
            <link
            rel="stylesheet"
            href="https://unpkg.com/img-comparison-slider@4/dist/styles.css"
            />
            <SEO title="Folientechnik-Design | Sonnenschutz-Folierung" />
            { ((scroll<height+offset)&&(scroll>=offset))? <Header colo="white" navcol="#fff" /> : <Header colo="black" navcol="#000" /> }
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap" rel="stylesheet" />
            <div style={{display: matchesPC? 'none':'inherit', height: '85px', zIndex: '99'}} align="center">
                <div align="center" style={{width: '100%', display: matchesPC? 'none':'inherit', height: '85px', position: 'absolute'}}>
                    <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                        <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechniks.png`} />
                    </a>
                </div>
            </div>
            {/* <SectionTitle title="Voll-Folierung" subtitle="Von außen werden alle sichtbaren Fl&auml;chen foliert. Je nach Wunsch k&ouml;nnen auch Leisten im Innenraum und in Türkannten &uuml;berzogen werden." /> */}
            <div style={styles}>
                <div>
                    <div className="floating" style={headwrap}>
                        <span data-depth="0.8">
                            <h1 style={head}>
                                VON PROFIS<br/>F&Uuml;R PROFIS
                            </h1>
                            <span className="AnfrageButton" style={{padding: matchesTB? '2px':'1em 1em'}}>
                                <Link to={process.env.PUBLIC_URL + "/contact"}>
                                    <p className="Anfrage" style={{fontFamily: 'Archivo Black', fontSize: matchesTB? '1.36vh':'1.4vw'}}>
                                        JETZT ANFRAGEN
                                    </p>
                                </Link>
                            </span>
                        </span>
                    </div>
                    <ImgComparisonSlider className="slider-split-line">
                        <img id="before" slot="before" width="100%" src="imgs/slider/gf1.png" />
                        <img slot="after" width="100%" src="imgs/slider/gf2.png" />
                    </ImgComparisonSlider>
                </div>
            </div>
            
            <div style={textcontainer} data-aos="fade-up">
                <div style={{paddingTop: '4vw'}}>
                    <h1 style={descriptionhead}>Sonnenschutz-Folierung</h1>
                    <div>
                        <p style={text}>
                            Sch&uuml;tzen Sie Ihr Geb&auml;ude gegen <strong>Sonneneinstrahlung</strong> und <strong>unerw&uuml;nschte Blicke</strong> von au&szlig;en. 
                            <br/>Im Sommer steigt die Raumtemperatur, durch die nach innen dringende Sonnenstrahlung, oft sehr stark an. Dies verursacht <strong>zus&auml;tzliche Stromkosten</strong>, die nicht sein m&uuml;ssen! Unsere <strong>Sonneschutzfolie</strong> ist hier von gro&szlig;em Nutzen. Sie dunkelt die Scheiben ab und sorgt daf&uuml;r, dass ungewollte Sonnenstrahlen nicht nach innen gelangen. Au&szlig;erdem wird durch unsere Sonnenschutzfolie, Displayspiegelung und unn&ouml;tige Belastung der Augen verhindert.
                        </p>
                    </div>
                    <h1 style={descriptionhead}>Sichtschutz-Folierung</h1>
                    <p style={text}>
                        F&uuml;r die, die eher am Sichtschutz interessiert sind, haben wir nat&uuml;rlich auch etwas da. Wir bieten auch <strong>Spiegelfolierungen</strong> an. Diese Folie ist einseitig durchsichtig und l&auml;sst Blicke von innen nach außen zu. Somit ist Ihr Geb&auml;ude, vor <strong>neugierigen Blicken</strong> und sogar <strong>Sonnenstrahlen</strong>, von außen vollst&auml;ndig gesch&uuml;tzt.
                    </p>
                   
                </div>
            </div>
            <Description3 />
            <Footer s="r"/>
            <ScrollToTop />
        
        </React.Fragment>
    )
}

export default Sonnenschutz;
