import {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import HomeOne from './pages/HomeOne';
import About from './pages/About';
import Voll from './pages/Voll';
import Teil from './pages/Teil';
import Scheiben from './pages/Scheiben';
import Werbe from './pages/Werbe';
import Contact from './pages/Contact';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import Haus from './pages/Haus'
import Sonnenschutz from './pages/Sonnenschutz';

// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';
import './assets/css/cookie_style.css';

function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeOne}/>
              <Route path={`${process.env.PUBLIC_URL + "/about"}`} component ={About} />
              <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component ={Contact} />
              <Route path={`${process.env.PUBLIC_URL + "/voll"}`} component ={Voll} />
              <Route path={`${process.env.PUBLIC_URL + "/teil"}`} component ={Teil} />
              <Route path={`${process.env.PUBLIC_URL + "/werbe"}`} component ={Werbe} />
              <Route path={`${process.env.PUBLIC_URL + "/scheiben"}`} component ={Scheiben} />
              <Route path={`${process.env.PUBLIC_URL + "/impressum"}`} component ={Impressum} />
              <Route path={`${process.env.PUBLIC_URL + "/datenschutz"}`} component ={Datenschutz} />
              <Route path={`${process.env.PUBLIC_URL + "/haus"}`} component ={Haus} />
              <Route path={`${process.env.PUBLIC_URL + "/sonnenschutz"}`} component ={Sonnenschutz} />
              {/* <Route component ={NotFound} /> */}
            </Switch>
          </NavScrollTop>
      </Router>
  );
}

export default App;
