import React from 'react'
import PropTypes from "prop-types";
import Tilt from 'react-parallax-tilt';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "../../assets/scss/_HomeFade.scss";

const PictureBox = ({side, image, image2, headline, title, text, link, linktext, m, p, single, noTilt, sig}) => {

    const matchesPC = useMediaQuery('(min-width:790px)');
    const matchesTB = useMediaQuery('(max-width:700px)');

    let img;
    if(single) {
        if(noTilt) {
            img = <img src={image} className="imagestylenotilt" alt="#"/>
        } else {
            img = (
                <Tilt scale={1.04} transitionSpeed={4000}>
                    <img src={image} className="imagestyle" alt="#"/>
                </Tilt>
            );
        }
    } else {
        if(noTilt) {
            <div>
                <img src={image} className="imagestylenotilt1" alt="#"/>
                <img src={image2} className="imagestylenotilt2" alt="#"/>
            </div>
        } else {
            img = (
                <div>
                    <Tilt scale={1.04} transitionSpeed={4000}>
                        <img src={image} className="imagestyle" alt="#"/>
                    </Tilt>
                    <Tilt scale={1.04} transitionSpeed={4000}>
                        <img src={image2} className="imagestyle2" alt="#"/>
                    </Tilt>
                </div>
            );
        }
    }

    return (
        <div>
            <div style={{display: matchesPC? 'flex':'none', marginTop: '4.6vh'}}>
                <div style={{width: side=='left'? '45%':'55%', padding: side=='left'? '2.6vw':`2.6vw ${!p? 2.1:0}vw`, fontSize: '2.1vh'}}>
                    {
                        side=='left'?
                            <div style={{marginLeft: '3.6vw'}}>
                                {img}
                            </div>
                        :
                            <div className="about-content-area" style={{marginLeft: !m? '30px':'0'}}>
                                <div className="section-title-two aos-init aos-animate" data-aos="fade-up">
                                    <span className="sub-title">{headline}</span>
                                    <h3 className="title">{title}</h3>
                                </div>
                                <p style={{fontSize: matchesTB? '2vh':'1.1vw'}}>{text}</p>
                                {sig?
                                    <div style={{width: '100%'}}>
                                        <div style={{width: '150px', margin: '0 auto', transform: 'rotate(0deg)'}}>
                                            <img src={'imgs/unterschrift-anatolij.jpg'} />
                                        </div>
                                    </div>:<div style={{height: '10px'}}></div>
                                }
                                <a href={link} style={{display: link==''? 'none':'inherit', marginTop: sig? '0':'7vh'}}>
                                    <button className="aboutbutton btun btun-border btun-arrow">
                                        <span>{linktext}
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.1 25.8" >
                                                <g>
                                                    <line fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" x1="0" y1="12.9" x2="34" y2="12.9" />
                                                    <polyline fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" points="22.2,1.1 34,12.9 22.2,24.7   " />
                                                </g>
                                            </svg>
                                        </span>
                                    </button>
                                </a>
                            </div>
                    }
                </div>
                <div style={{width: side=='left'? '55%':'45%', fontSize: '2.1vh', padding: side=='left'? `2.6vw ${!p? 2.1:0}vw`:'2.6vw'}}>
                    {
                        side=='left'? 
                            <div className="about-content-area" style={{marginRight: !m? '30px':'0'}}>
                                <div className="section-title-two aos-init aos-animate" data-aos="fade-up">
                                    <span className="sub-title">{headline}</span>
                                    <h3 className="title">{title}</h3>
                                </div>
                                <p style={{fontSize: matchesTB? '2vh':'1.1vw'}}>{text}</p>
                                {sig?
                                    <div style={{width: '100%'}}>
                                        <div style={{width: '150px', margin: '0 auto', transform: 'rotate(0deg)'}}>
                                            <img src={'imgs/unterschrift-anatolij.jpg'} />
                                        </div>
                                    </div>:<div style={{height: '10px'}}></div>
                                }
                                <a href={link} style={{display: link==''? 'none':'inherit', marginTop: sig? '0':'7vh'}}>
                                    <button className="aboutbutton btun btun-border btun-arrow">
                                        <span>{linktext}
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.1 25.8" >
                                                <g>
                                                    <line fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" x1="0" y1="12.9" x2="34" y2="12.9" />
                                                    <polyline fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" points="22.2,1.1 34,12.9 22.2,24.7   " />
                                                </g>
                                            </svg>
                                        </span>
                                    </button>
                                </a>
                            </div>
                        :
                            <div style={{marginRight: '3.6vw'}}>
                                {img}
                            </div>
                    }
                </div>
            </div>



            <div style={{display: matchesPC? 'none':'flex', flexDirection: 'column', margin: '6vh 0vh'}}>
                <div className="about-content-area">
                    <div className="section-title-two aos-init aos-animate" data-aos="fade-up">
                        <span className="sub-title">{headline}</span>
                        <h3 className="title">{title}</h3>
                    </div>
                    <Tilt scale={1.04} transitionSpeed={4000}>
                        <img src={image} style={{borderRadius: '1vw', boxShadow: '1vh 1vh 2vh silver'}} alt="#"/>
                    </Tilt>
                    <p style={{marginTop: '2vh', fontSize: matchesTB? '2vh':'1.1vw'}}>{text}</p>
                    {sig?
                                    <div style={{width: '100%'}}>
                                        <div style={{width: '150px', transform: 'rotate(0deg)'}}>
                                            <img src={'imgs/unterschrift-anatolij.jpg'} />
                                        </div>
                                    </div>:<div style={{height: '10px'}}></div>
                                }
                    <a href={link} style={{display: link==''? 'none':'inherit', marginTop: sig? '0':'7vh'}}>
                        <button className="aboutbutton btun btun-border btun-arrow">
                            <span>{linktext}
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.1 25.8" >
                                    <g>
                                        <line fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" x1="0" y1="12.9" x2="34" y2="12.9" />
                                        <polyline fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" points="22.2,1.1 34,12.9 22.2,24.7   " />
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </a>
                </div>
            </div>
        </div>
        
    )
}

PictureBox.propTypes = {
    side: PropTypes.string,
    image: PropTypes.string,
    image2: PropTypes.string,
    headline: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    linktext: PropTypes.string,
    m: PropTypes.bool,
    p: PropTypes.bool,
    single: PropTypes.bool,
    noTilt: PropTypes.bool,
    sig: PropTypes.bool
};

export default PictureBox