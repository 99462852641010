import React from 'react';
import app from '../../assets/scripts/cook.js';

const Footer = () => {


    return (
        <div id="cookie-section">
            <div id="consent-popup" className="hidden">
                <div id="cookie-main" className="active" style={{fontFamily: 'Arimo'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <a href="#" id="deny"></a>
                    </div>
                    <h3 className="cookie-head">NICE <span style={{color: '#1ea76a'}}>TO <br/>MEET</span> YOU</h3>
                    <h3 style={{fontFamily: 'Arimo', fontWeight: '500', marginBottom: '2.5em', marginTop: '2em'}}>Unsere Seite benutzt <span style={{fontWeight: 'bold'}}>Cookies.</span><br/> Beim betreten von Folientechnik-Design.de stimmst du unseren <a href={`${process.env.PUBLIC_URL}/datenschutz`} style={{color: '#000', textDecoration: 'underline'}}>Gesch&auml;ftsbedingungen</a> zu. Akzeptiere unsere Cookies, bevor du diese Seite betrittst.
                    </h3>
                    <a id="accept" className="btn-cookies" href="#"><span className="text-cookies">Alle akzeptieren</span></a>
                    <a id="settings" href="#" style={{display: 'inline-block', color: '#9c9c9c', marginBottom: '10px', width: '100%'}}>individuelle Datenschutzeinstellungen</a>
                    <a id="settings" href={`${process.env.PUBLIC_URL}/impressum`} style={{display: 'inline-block', color: '#9c9c9c'}}>Impressum</a>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <a id="settings" href={`${process.env.PUBLIC_URL}/datenschutz`} style={{display: 'inline-block', color: '#9c9c9c'}}>Datenschutzerkl&auml;rung</a>
                        <span style={{color: '#9c9c9c'}}>Powered by <a href="https://labrigart.de" style={{color: '#ffcc08'}}>Labrigart</a></span>
                    </div>
                </div>
                
                <div id="cookie-settings" className="hide" style={{fontFamily: 'Arimo'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <a href="#" id="deny"></a>
                    </div>
                    <h3 className="cookie-head">NICE <span style={{color: '#1ea76a'}}>TO <br/>MEET</span> YOU</h3>
                    <p style={{color: '#000'}}>Diese Cookies verwenden wir:</p>
                    <label htmlFor="marketing" style={{color: '#000', display: 'inline-block', margin: `10px 0`}}>
                        <input type="checkbox" id="marketing" name="marketing"/> Google Maps
                    </label>
                    <br/>
                    <label htmlFor="essential" style={{color: '#000', display: 'inline-block', margin: `10px 0`, pointerEvents: 'none', marginBottom: '2.5em'}}>
                        <input type="checkbox" id="essential" name="essential" checked/> Essentiell
                    </label>
                    <a id="save" className="btn-cookies" href="#"><span className="text-cookies">Einstellungen speichern</span></a>
                    <a id="cback" href="#" style={{display: 'inline-block', marginBottom: '10px', color: '#9c9c9c', width: '100%'}}>zur&uuml;ck</a>
                    <a id="settings" href={`${process.env.PUBLIC_URL}/impressum`} style={{display: 'inline-block', color: '#9c9c9c'}}>Impressum</a>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <a id="settings" href={`${process.env.PUBLIC_URL}/datenschutz`} style={{display: 'inline-block', color: '#9c9c9c'}}>Datenschutzerkl&auml;rung</a>
                        <span style={{color: '#9c9c9c'}}>Powered by <a href="https://labrigart.de" style={{color: '#ffcc08'}}>Labrigart</a></span>
                    </div>
                </div>
            </div>
            <script id="gaAllow"></script>
            <script src={app}></script>
        </div>
    )
}

export default Footer;
