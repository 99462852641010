function sketch(p) {

    var canvas;
    let img;

    let h = document.getElementById("bglambo").offsetHeight;
    let w = document.getElementById("bglambo").offsetWidth;

    p.setup = function () {
        canvas = p.createCanvas(w,h);
        canvas.position(0, w>1080? 0:85);
        p.background(0, 0, 0, 0);
        img = p.loadImage('imgs/autos/car_blau.png');
    }

    p.draw = function () {
        p.clear();
        p.fill(0);
        p.image(img, window.pageYOffset*p.windowWidth/1000, 0, w, h);
    }
}

export default sketch;