import React from 'react';
import { useForm } from "react-hook-form";

const ContactFrom = () => {
    const { register, errors } = useForm({
        mode: "onBlur"
    });
    return (
        <div className="contact-form">
            <form action="mail.php" method="POST">
                <div className="row mb-n6">
                    <div className="col-md-6 col-12 mb-6">
                        <input type="text" placeholder="Name *" name="name" ref={register({ required: 'Bitte gib deinen Namen an' })} />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="email" placeholder="Email *" name="email" ref={register({
                            required: 'Bitte gib deine Email-Adresse an',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Inkorrekte Email-Adresse"
                            }
                        })} />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <div className="col-12 mb-6">
                        <textarea name="message" placeholder="Nachricht *" ref={register({ required: 'Bitte gib deine Nachricht ein' })}></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                    <input type="hidden" name="index" value={process.env.PUBLIC_URL + "/"}/>
                    <div className="col-12 text-center mb-6">
                        <button style={{backgroundColor: '#1ea76a', borderColor: '#1ea76a'}} type="submit" name="submit" className="btn btn-primary btn-hover-secondary">Senden</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ContactFrom;
