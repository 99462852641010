import React from 'react';

const GoogleMap = () => {

    const cookieStorage = {
        getItem: (item) => {
            const cookies = document.cookie
                .split(';')
                .map(cookie => cookie.split('='))
                .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
            return cookies[item];
        },
        setItem: (item, value) => {
            let date = new Date();
            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = `${item}=${value}; ${expires};`
        }
    }

    const storageType = cookieStorage;
    const consentPropertyName = 'Google_consent';

    let googl;


    if(storageType.getItem(consentPropertyName) == 'deny') {
        googl = (
            <div style={{height: '120px'}} />
    );
    } else {
        googl = (
            <div className="google-map-area section text-center section-padding-bottom">
                <div className="container">
                    <div className="contact-map-area" data-aos="fade-up">
                    <div style={{height: '120px'}} />
                        <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10627.977400793423!2d9.9284609!3d48.2452362!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6d57ce4cb5e12ffd!2sFolientechnik%20-%20Design!5e0!3m2!1sde!2sde!4v1645628695414!5m2!1sde!2sde"  aria-hidden="false"></iframe>
                    </div>
                </div>
            </div> 
        );
    }

    return (
        <React.Fragment>
            {googl}
        </React.Fragment>
    )
}

export default GoogleMap;