import PropTypes from "prop-types";
import contactData from '../../data/contactInfo/contactInfo.json';
import ContactInfoItem from '../../components/ContactInfo/ContactInfoItem.jsx';


const ContactInformation = ({ classOption, colo, w }) => {

    const matchesPC = screen.width>1080? true:false;

    const styles = {
        width: matchesPC? w:'100%',
        margin: '0 auto'
    };

    return (
        <div className={`section section-padding-t90-b100 ${classOption}`} style={{backgroundColor: colo}}>
            <div className="container shape-animate">
                <div style={styles} className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                    {contactData && contactData.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6">
                                <ContactInfoItem data={single} key={key} />
                            </div>
                        ); 
                    })}
                </div>
            </div>
        </div>
    )
}

ContactInformation.propTypes = {
    classOption: PropTypes.string,
    colo: PropTypes.string,
    w: PropTypes.string
};
ContactInformation.defaultProps = {
    classOption: "section section-padding-t90-b100"
};

export default ContactInformation
