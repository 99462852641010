import React from 'react'
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
// import section from '../components/SectionTitles/SectionTitle';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link} from 'react-router-dom';
import Description3 from '../container/Description/Description3';
// import Logo from '../components/logo/Logo';
// import PictureBox from '../container/About/PictureBox';
import "../assets/scss/_HomeFade.scss";



const Scheiben = () => {

    const matchesPC = useMediaQuery('(min-width:1200px)');

    // const path = 'imgs/about/FOTO';

    // const matchesPC = useMediaQuery('(min-width:550px)');
    const matchesTB = useMediaQuery('(max-width:700px)');

    const styles = {
        display: 'block',
        justifyContent: 'space-evenly'
        //marginLeft: '25%'
    }

    const head = {
        fontFamily: 'Archivo Black',
        fontSize: '5vw',
        fontWeight: '1000',
        color: '#000',
        marginBottom: '2vh',
        lineHeight: '1.1'
    }

    const headwrap = {
        position: 'absolute',
        zIndex: '1',
        marginLeft: '10%',
        marginTop: '7%',
    }

    const descriptionhead = {
        fontSize: matchesTB? '3vh':'3vw',
        textAlign: 'center'
    }

    const textcontainer = {
        width: '88%',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0px 5px 83px 0px rgba(9, 29, 62, 0.15)',
        borderRadius: '12px',
        marginTop: '-9vw',
        zIndex: '2',
        position: 'relative',
        padding: '0px 10% 40px'
    }

    const text = {
        textAlign: 'left',
        fontSize: matchesTB? '2vh':'1.1vw',
        marginTop: '3vh',
        marginBottom: '5vh',
        padding: '2% 0'
    }

    return (
        <React.Fragment>
            <img style={{position: 'absolute'}} src="imgs/h1-backround-1png.png"/>
            <script
                defer
                src="https://unpkg.com/img-comparison-slider@4/dist/index.js"
            ></script>
            <link
            rel="stylesheet"
            href="https://unpkg.com/img-comparison-slider@4/dist/styles.css"
            />
            <SEO title="Folientechnik-Design | Scheibentönung" />
            <Header navcol='#000' colo='black'/>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap" rel="stylesheet" />
            <div style={{display: matchesPC? 'none':'inherit', height: '85px', zIndex: '99'}} align="center">
                <div align="center" style={{width: '100%', display: matchesPC? 'none':'inherit', height: '85px', position: 'absolute'}}>
                    <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                        <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechniks.png`} />
                    </a>
                </div>
            </div>
            {/* <SectionTitle title="Voll-Folierung" subtitle="Von außen werden alle sichtbaren Fl&auml;chen foliert. Je nach Wunsch k&ouml;nnen auch Leisten im Innenraum und in Türkannten &uuml;berzogen werden." /> */}
            <div style={styles}>
                <div>
                    <div className="floating" style={headwrap}>
                        <span data-depth="0.8">
                            <h1 style={head}>
                                VON PROFIS<br/>F&Uuml;R PROFIS
                            </h1>
                            <span className="AnfrageButton" style={{padding: matchesTB? '2px':'1em 1em'}}>
                                <Link to={process.env.PUBLIC_URL + "/contact"}>
                                    <p className="Anfrage" style={{fontFamily: 'Archivo Black', fontSize: matchesTB? '1.36vh':'1.4vw'}}>
                                        JETZT ANFRAGEN
                                    </p>
                                </Link>
                            </span>
                        </span>
                    </div>
                    <ImgComparisonSlider className="slider-split-line">
                        <img slot="before" width="100%" src="imgs/slider/s1.jpg" />
                        <img slot="after" width="100%" src="imgs/slider/s2.jpg" />
                    </ImgComparisonSlider>
                </div>
            </div>
            <div style={textcontainer} data-aos="fade-up">
                <div style={{paddingTop: '4vw'}}>
                    <h1 style={descriptionhead}>Scheibent&ouml;nung</h1>
                    <p style={text}>
                        Durch eine einfache <strong>Scheibent&ouml;nungsfolie</strong> erreichen Sie, für Ihr Auto, einen eleganteren Look. Außerdem gelangt weniger Hitze in den Innenraum. Diese Folien gibt es in verschiedenen Varianten. Sie unterschieden sich in der st&auml;rke der T&ouml;nung. 
                    </p>
                    
                </div>
            </div>
            <Description3 />
            <Footer s="r"/>
            <ScrollToTop />
        
        </React.Fragment>
    )
}

export default Scheiben;
