cook();
export default function cook() {
    const cookieStorage = {
        getItem: (item) => {
            const cookies = document.cookie
                .split(';')
                .map(cookie => cookie.split('='))
                .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
            return cookies[item];
        },
        setItem: (item, value) => {
            let date = new Date();
            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = `${item}=${value}; ${expires}; SameSite=Lax`
        }
    }

    const storageType = cookieStorage;
    const consentPropertyName = 'Google_Maps_consent';
    const shouldShowPopup = () => !storageType.getItem(consentPropertyName);

    window.onload = () => {

        document.getElementById("cookie-section").style.pointerEvents = "all";

        const acceptFn = () => {
            storageType.setItem(consentPropertyName, 'allow');
            consentPopup.classList.add('hidden');
            document.getElementById("gaAllow").src = "//www.google-analytics.com/analytics.js";
            document.getElementById("gaAllow").async = "";
            document.getElementById('root').style.pointerEvents = 'auto';
            document.getElementById("cookie-section").style.display = "none";
            window.addEventListener("mousemove", hideeverything);
            window.addEventListener("touchend", hideeverything);
        }
        const denyFn = () => {
            storageType.setItem(consentPropertyName, 'deny');
            consentPopup.classList.add('hidden');
            document.getElementById('root').style.pointerEvents = 'all';
            document.getElementById("cookie-section").style.display = "none";
            window.addEventListener("mousemove", hideeverything);
            window.addEventListener("touchend", hideeverything);
        }
        const hideFn = () => {
            cookieMain.classList.add('hide');
            cookieMain.classList.remove('active');
            cookieSettings.classList.add('active');
            cookieSettings.classList.remove('hide');
        }
        const showFn = () => {
            cookieMain.classList.add('active');
            cookieMain.classList.remove('hide');
            cookieSettings.classList.add('hide');
            cookieSettings.classList.remove('active');
        }
        const saveFn = () => {
            if(!cb.checked) {
                denyBtn.click();
            } else if(cb.checked) {
                acceptBtn.click();
            }
        }

        const hideeverything = () => {
            document.getElementsByTagName("html")[0].style.setProperty ("--bs-google-visible", "none");
            document.getElementById("cookie-section").style.display = "none";
        }

        if(!shouldShowPopup(storageType)) {
            hideeverything();
        }

        var r = document.querySelector(':root');
        const consentPopup = document.getElementById('consent-popup');
        const cookieMain = document.getElementById('cookie-main');
        const cookieSettings = document.getElementById('cookie-settings');
        
        const acceptBtn = document.getElementById('accept');
        acceptBtn.addEventListener('click', acceptFn);
        
        const denyBtn = document.getElementById('deny');
        denyBtn.addEventListener('click', denyFn);
        
        const settingsBtn = document.getElementById('settings');
        settingsBtn.addEventListener('click', hideFn);
        
        const mainBtn = document.getElementById('cback');
        mainBtn.addEventListener('click', showFn);
        
        const cb = document.getElementById('marketing');
        const saveBtn = document.getElementById('save');
        saveBtn.addEventListener('click', saveFn);

        if (shouldShowPopup(storageType)) {
            document.getElementById('root').style.pointerEvents = 'none';
            r.style.setProperty('--bs-google-visible', 'inherit');
            setTimeout(() => {
                consentPopup.classList.remove('hidden');
            }, 2000);
        } else {
            if(storageType.getItem(consentPropertyName) == 'deny') {
                //Wenn cookie Banner schon gezeigt und verneint
                r.style.setProperty('--bs-google-visible', 'none');
                r.style.setProperty('--bs-form-margin', '0');
            }
            document.getElementsByTagName("html")[0].style.setProperty ("--bs-google-visible", "none");
            document.getElementById("cookie-section").style.display = "none";
        }

    };
}