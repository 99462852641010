import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function Description() {

    const descriptionTitle = {
        fontWeight: 'bold',
    }

    const matchesTB = useMediaQuery('(max-width:1200px)');

    const textcontainer = {
        width: '90%',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0px 5px 83px 0px rgba(9, 29, 62, 0.15)',
        marginTop: '-2vw',
        zIndex: '2',
        position: 'relative',
        color: '#000'
    }

    const text = {
        padding: '2% 0',
        textAlign: 'left',
        listStyle: 'none',
        fontSize: matchesTB? '13px':'18px'
    }

    return (
        <div style={textcontainer}>
            <div style={{padding: '2vw 4%'}}>
                <ul style={text}>
                    <li data-aos="fade-up">
                        <a href="/voll" style={descriptionTitle}>Voll-Folierung:&nbsp;</a>
                        Von außen werden alle sichtbaren Flächen foliert. Je nach Wunsch können auch Leisten im Innenraum und Türkanten überzogen werden.
                    </li>
                    <br/>
                    <li data-aos="fade-up">
                        <a href="/teil" style={descriptionTitle}>Teil-Folierung:&nbsp;</a>
                        Dabei werden nur einzelne Muster oder Flächen foliert.
                    </li>
                    <br/>
                    <li data-aos="fade-up">
                        <a href="/werbe" style={descriptionTitle}>Werbe-Folierung:&nbsp;</a>
                        Wir erstellen eine individuelle Beschriftung mit Ihrem Firmenlogo. Diese wird perfekt auf Ihrem Fahrzeug angebracht um das nötige Aufsehen zu erregen.
                    </li>
                    <br/>
                    <li data-aos="fade-up">
                        <a href="/teil" style={descriptionTitle}>Lackschutz-Folierung:&nbsp;</a>
                        Meistens wird dabei nur der Frontbereich foliert, um den Lack vor Kratzern und Steinschlägen zu schützen. Die Folie ist transparent, somit unauffällig und perfekt, um Ihr Auto zu schützen.
                    </li>
                    <br/>
                    <li data-aos="fade-up">
                        <a href="/scheiben" style={descriptionTitle}>Scheibent&ouml;nung:&nbsp;</a>
                        Die hinteren Scheiben, sowie die Heckscheibe werden getönt. Die Tönung kann dabei selbst bestimmt werden. Außerdem bietet die Scheibenfolierung Schutz vor UV-Strahlen, starker Hitze und vor ungewünschten Blicken in den Innenraum.
                    </li>
                </ul>
            </div>
        </div>
    )
}
