import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import ContactFrom from '../../components/ContactFrom/ContactFrom.jsx';

const ContactFromContainer = () => {

    const textcontainer = {
        width: '80%',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0px 5px 83px 0px rgba(9, 29, 62, 0.15)',
        borderRadius: '12px',
        zIndex: '2',
        position: 'relative'
    }

    return (
        <div style={textcontainer} className="contact-form-section section section-padding-t90-b100 bg-primary-blue form-margin">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <SectionTitle
                            headingOption="fz-32"
                            title="Interesse bekommen?"
                            subTitle="Kontaktiere uns und teile uns deinen Wunsch mit"
                        />
                        <ContactFrom />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactFromContainer
