import React from 'react';
import {Link} from "react-router-dom";
import Logo from '../../components/logo/Logo';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';
import Cookies from '../../components/Cookies/Cookies.jsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from "prop-types"

const Footer = ({s}) => {

    const matchesPC = useMediaQuery('(min-width:1080px)');

    return (
        <div className="footer-section section footer-bg-color" style={{position: s=='r'? 'relative':'static'}}>
            <div className="container" style={{width: matchesPC? '70%':''}}>
                <div className="row mb-lg-14 mb-md-10 mb-6">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget" style={{marginTop: '-50px'}}>
                            <div className="footer-logo">
                                <Logo 
                                    footer={matchesPC? true:false}
                                    image={`${process.env.PUBLIC_URL}/imgs/folientechnikw.png`}
                                />
                            </div>
                            <div className="footer-widget-content">
                                <div className="content">


                                    <p><Link to={process.env.PUBLIC_URL + "/"}>+49 174 3291916<br/>Flurweg 55/1<br/>88483 Burgrieden<br/><b>TERMINE NUR NACH TELEFONISCHER ABSPRACHE</b></Link></p>
                                </div>
                                <div className="footer-social-inline">
                                    <a href="https://www.instagram.com/folientechnik.design"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {FooterData && FooterData.map((single, key) => {
                            return(
                                <div style={{width: '30% !important', margin: '0px 10px'}} key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                                    <FooterLinkItem data={single} key={key} />
                                </div>
                            ); 
                    })}


                </div>

                <div className="row">
                    <div className="col">
                        <p className="copyright">&copy; 2021 <strong>Folientechnik-Design,</strong> Powered by <a target="_blank" rel="noopener noreferrer" href="https://Labrigart.de/"><strong>Labrigart</strong></a>.</p>
                    </div>
                </div>

            </div>
         <Cookies/>
        </div>
    )
}


Footer.propTypes = {
    s: PropTypes.string
}


export default Footer;
