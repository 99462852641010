import {Fragment, useState, useEffect} from "react";
import NavBar from '../../components/NavBar/NavBar';
import MobileMenu from "../../components/NavBar/MobileMenu";
// import MainSearch from "../../components/NavBar/MainSearch";
import PropTypes from "prop-types"
// import HeaderSearch from '../../components/HeaderSearch/HeaderSearch';
// import useMediaQuery from '@material-ui/core/useMediaQuery';


const Header = ({navcol, colo}) => {

    // const matchesPC = useMediaQuery('(min-width:1379px)');

    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const header = document.querySelector(".header-section");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <Fragment>
            <div className={`header-section header-transparent sticky-header section ${scroll > headerTop ? "is-sticky" : ""}`}>
                <div style={{width: '100vw'}} className="header-inner">
                    <div className="container position-relative">
                        <div className="row justify-content-between align-items-center">

                            <div style={{padding: '0px !important'}} className="col-auto col-xl align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                                <div className="menu-column-area d-none d-xl-block position-static">
                                    <NavBar colo={navcol} />
                                </div>
                                

                                <div className={`${colo=='black'? 'header-mobile-menu-toggle2':'header-mobile-menu-toggle'} d-xl-none ml-sm-2`}>
                                    <button type="button" className="toggle" onClick={onCanvasHandler}>
                                        <i className="icon-top"></i>
                                        <i className="icon-middle"></i>
                                        <i className="icon-bottom"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="header-search-area" style={{marginRight: '40px', display: scroll>headerTop? 'none':'inherit'}}>
                        <HeaderSearch onClick={onSearchHandler}/>
                    </div> */}
                </div>
            </div>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}/>
            {/* <MainSearch show={searchbarShow} onClose={onSearchHandler}/> */}
        </Fragment>
    )
}

Header.propTypes = {
    navcol: PropTypes.string,
    colo: PropTypes.string
}


export default Header;