import SectionTitle from '../SectionTitles/SectionTitle';
import { Link} from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import "../../assets/scss/_HomeFade.scss";
// import useMediaQuery from '@material-ui/core/useMediaQuery';

const HomeAbout = () => {

    // const matchesPC = useMediaQuery('(min-width:1080px)');

    const styles = {
        display: 'flex'
    }

    const style25 = {
        width: '25%',
        display: 'flex'
    }

    const style50 = {
        width: '50%'
    }

    return (
        
            <div style={{marginBottom: '10vh'}}>
                <SectionTitle title="Willkommen auf Folientechnik-Design" />
                <div style={styles}>  
                    <div data-aos="fade-up" data-aos-delay="300" style={style25} />
                    <div data-aos="fade-up" style={style50}>
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={1.1} transitionSpeed={4000}>
                                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                                        <img src={process.env.PUBLIC_URL + "imgs/businesscards.jpg"} alt="" />
                                    </Link>
                                </Tilt>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
    )
}

export default HomeAbout;
