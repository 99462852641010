import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import { useState, useEffect} from "react";
// import HomeSuccess from '../components/Success/HomeSuccess';
// import HomeBlog from '../container/BlogGrid/HomeBlog';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import Auto from '../container/Auto/Auto';
import HomeFade from '../container/HomeFade/HomeFade'
import Visitenkarte from '../components/About/Visitenkarte';
import Icons2 from '../components/IconBox/Icons2';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import Description2 from '../container/Description/Description2';
import Description from '../container/Description/Description';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';


const HomeOne = () => {

    const height = 0.4*screen.width;
    const iconheight = -((0.7*0.25*screen.width)+0.05*screen.width+95) + (0.04*2*screen.width);
    // const offset = matchesPC? 0 : 85;
    
    const matchesPC = screen.width>1080? true:false;
    const h = 7000;
    let bot = false;
    const [bottom, setBottom] = useState(false);
    const inuse = 0.7285*screen.width + 600;

    const [scroll, setScroll] = useState(0);
    // const [bottom, setBottom] = useState(false);
    // let bot = false;
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, {passive: true} );
        return () => {
        window.removeEventListener("scroll", handleScroll, {passive: true});
        };

    }, []);

    

    const handleScroll = () => {   
        setScroll(window.pageYOffset);
        if(window.pageYOffset>inuse+700 && !bot) {
            bot = true;
            setBottom(bot);
            document.getElementById(`carpoints${matchesPC? 1:2}`).scrollIntoView();
        }
    };

    return (
        <React.Fragment>
            <div>
            { scroll<height? <Header colo="white" navcol="#fff" /> : <Header colo="black" navcol="#000" /> }
                <div align="center" style={{width: '100%', display: matchesPC? 'none':'inherit', height: '85px', position: 'absolute'}}>
                    <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                        <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechnikw.png`} />
                    </a>
                </div>
                <div align="center">
                    <SEO title="Folientechnik-Design" />
                    <Auto />
                    <div>
                        <img style={{position: 'absolute', left: '0'}} src="imgs/h1-backround-1png.png"/>                        
                        <div style={{width: matchesPC? '70vw':'100vw'}}>
                            <Visitenkarte />
                        </div>
                    </div>
                </div>
                <div id="carpoints2" style={{position: 'relative', zIndex: '11', height: bottom? '':(h+'px')}}>
                    <div style={{position: 'sticky', top: matchesPC? iconheight+'px':'5%'}}>
                        <div style={{width: matchesPC? '70vw':'90vw', margin: '0px auto', textAlign: 'center'}}>
                            <Icons2 />
                        </div>
                        <HomeFade />
                        <div id="description" className="bg-primary-blue">
                            <Description2 />
                            <Description />
                            <ContactInformation colo='#bbbbb' w="93%"/>
                            <TestimonialContainer classOption="bg-primary-blue" />
                        </div>
                    <Footer />
                    </div>
                </div>
                <ScrollToTop />
            </div>
        </React.Fragment>
    )
}

export default HomeOne;



