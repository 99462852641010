import React from 'react';
import PropTypes from "prop-types"
import Logo from '../../components/logo/Logo';
import MobileNavMenu from './sub-component/MobileNavMenu';
import {NavLink} from 'react-router-dom';

const MobileMenu = ({show, onClose}) => {
    return (
        <div id="site-main-mobile-menu" className={`site-main-mobile-menu ${show ? "active": ""}`}>
        <div style={{display: 'flex', flexDirection: 'column'}} className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo" style={{marginTop: '-20px'}}>
                    <a href="/">
                        <Logo image={process.env.PUBLIC_URL + "/imgs/folientechnikw.png"} />
                    </a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle" onClick={onClose}>
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <MobileNavMenu />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: '20px 0'}}>
                <NavLink to={process.env.PUBLIC_URL + "/impressum"}><span style={{color: '#aaa'}}>Impressum</span></NavLink>
                <NavLink to={process.env.PUBLIC_URL + "/datenschutz"}><span style={{color: '#aaa'}}>Datenschutz</span></NavLink>
            </div>
        </div>
    </div>
    )
}

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func
}

export default MobileMenu;
