import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import ContactInformation from '../container/ContactInformation/ContactInformation';
import GoogleMap from '../container/Map/GoogleMap';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Contact = () => {

    const matchesPC = useMediaQuery('(min-width:1200px)');

    const background = {
        backgroundImage: 'url(imgs/h1-backround-2png.png)'
    }

    return (
        <React.Fragment>
            <SEO title="Folientechnik-Design | Kontakt" />
            <Header colo="black" navcol='#000'/>
            <div align="center" style={{width: '100%', display: matchesPC? 'none':'inherit', height: '85px', position: 'absolute'}}>
                <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                    <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechniks.png`} />
                </a>
            </div>
            <GoogleMap />
            <div style={background}>
                <img style={{position: 'absolute'}} src="imgs/h1-backround-1png.png"/>
                <ContactFromContainer />
                <ContactInformation w="83%"/>
            </div>
            <Footer s="r"/>
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Contact;