import React from 'react'
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';

export default function Datenschutz() {

    return (
        <div>
            <Header colo="black" navcol='#000'/>
            <div align="center" className="mobile-header-custom">
                <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                    <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechniks.png`} />
                </a>
            </div>
            <img src="imgs/h1-backround-2png.png" style={{position: 'absolute', zIndex: 0}}/>
            <img src="imgs/h1-backround-1png.png" style={{position: 'absolute', zIndex: 0}}/>
            <div style={{margin: '140px auto', width: '80%', position: 'relative'}}>
                <p>
                    <h1>Datenschutzerkl&auml;rung</h1>
                </p> 
                <p>
                    <strong>Verantwortlicher f&uuml;r die Datenverarbeitung ist:</strong>
                    <br /> Anatolij Rot<br /> Wielandstr. 12<br /> Oberholzheim<br /> Deutschland<br /> info@folientechnik-design.de
                </p> 
                <p>Telefon: 01743291916</p> 
                <p>Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.<br/></p> 
                <h2>1.&nbsp;Zugriffsdaten und Hosting</h2> 
                <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.</p> 
                <h3>&nbsp;Hosting</h3> 
                <p>Die Dienste zum Hosting und zur Darstellung der Webseite werden teilweise durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag erbracht. Soweit im Rahmen der vorliegenden Datenschutzerklärung nichts anderes erläutert wird, werden alle Zugriffsdaten sowie alle Daten, die in dafür vorgesehenen Formularen auf dieser Webseite erhoben werden, auf ihren Servern verarbeitet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.</p> 
                <p>Unsere Dienstleister sitzen und/oder verwenden Server in L&auml;ndern au&szlig;erhalb der EU und des EWR.&nbsp;F&uuml;r diese L&auml;nder liegt kein Angemessenheitsbeschluss der Europ&auml;ischen Kommission vor. Unsere Zusammenarbeit mit ihnen st&uuml;tzt sich auf Standarddatenschutzklauseln der Europ&auml;ischen Kommission.&nbsp;</p> 
                <h2>2.&nbsp;Datenverarbeitung zur Vertragsabwicklung, Kontaktaufnahme und bei Eröffnung eines Kundenkontos</h2> 
                <p>Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren Angabe die Bestellung bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von Ihnen mitgeteilten Daten zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO.<br/>Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, indem Sie sich für die Eröffnung eines Kundenkontos entscheiden, verwenden wir Ihre Daten zum Zwecke der Kundenkontoeröffnung. Weitere Informationen zu der Verarbeitung Ihrer Daten, insbesondere zu der Weitergabe an unsere Dienstleister zum Zwecke der Bestellungs-, Zahlungs- und Versandabwicklung, finden Sie in den nachfolgenden Abschnitten dieser Datenschutzerklärung. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren. Die Löschung Ihres Kundenkontos ist jederzeit möglich und kann entweder durch eine Nachricht an die in dieser Datenschutzerklärung beschriebene Kontaktmöglichkeit oder über eine dafür vorgesehene Funktion im Kundenkonto erfolgen.</p> 
                <h2>3.&nbsp;Datenverarbeitung zum Zwecke der Versandabwicklung</h2> <p>Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an den mit der Lieferung beauftragten Versanddienstleister weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist.<br/></p> <h2>4.&nbsp;Datenverarbeitung zur Zahlungsabwicklung</h2> 
                <p>Bei der Abwicklung von Zahlungen in unserem Online-Shop arbeiten wir mit diesen Partnern zusammen: technische Dienstleister, Kreditinstitute, Zahlungsdienstleister.</p> 
                <h3>4.1&nbsp;Datenverarbeitung zur Transaktionsabwicklung</h3> 
                <p>Je nach ausgew&auml;hlter Zahlungsart geben wir die f&uuml;r die Abwicklung der Zahlungstransaktion notwendigen Daten an unsere technischen Dienstleister, die im Rahmen einer Auftragsverarbeitung f&uuml;r uns t&auml;tig sind, oder an die beauftragten Kreditinstitute oder an den ausgew&auml;hlten Zahlungsdienstleister weiter, soweit dies zur Abwicklung der Zahlung erforderlich ist. Dies dient der Vertragserf&uuml;llung gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. b DSGVO. Zum Teil erheben die Zahlungsdienstleister die f&uuml;r die Abwicklung der Zahlung erforderlichen Daten selbst, z.B. auf ihrer eigenen Webseite oder &uuml;ber eine technische Einbindung im Bestellprozess. Es gilt insoweit die Datenschutzerkl&auml;rung des jeweiligen Zahlungsdienstleisters.<br /> Bei Fragen zu unseren Partnern f&uuml;r die Zahlungsabwicklung und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerkl&auml;rung beschriebenen Kontaktm&ouml;glichkeit.</p> 
                <h3>4.2&nbsp;Datenverarbeitung zum Zwecke der Betrugspr&auml;vention und der Optimierung unserer Zahlungsprozesse</h3> 
                <p>Gegebenenfalls geben wir unseren Dienstleistern weitere Daten, die sie zusammen mit den für die Abwicklung der Zahlung notwendigen Daten als unsere Auftragsverarbeiter zum Zwecke der Betrugsprävention und der Optimierung unserer Zahlungsprozesse (z.B. Rechnungsstellung, Abwicklung von angefochtenen Zahlungen, Unterstützung der Buchhaltung) verwenden. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an unserer Absicherung gegen Betrug bzw. an einem effizienten Zahlungsmanagement.</p> 
                <h2>5.&nbsp;Cookies und weitere Technologien</h2> 
                <h3>&nbsp;Allgemeine Informationen</h3> 
                <p>Um den Besuch unserer Webseite attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf verschiedenen Seiten Technologien einschlie&szlig;lich sogenannter Cookies. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endger&auml;t gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endger&auml;t und erm&ouml;glichen uns, Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen (persistente Cookies).<br /> Wir verwenden solche Technologien, die f&uuml;r die Nutzung bestimmter Funktionen unserer Webseite (z.B. Warenkorbfunktion) zwingend erforderlich sind. Durch diese Technologien werden IP-Adresse, Zeitpunkt des Besuchs, Ger&auml;te- und Browser-Informationen sowie Informationen zu Ihrer Nutzung unserer Webseite (z. B. Informationen zum Inhalt des Warenkorbs) erhoben und verarbeitet. Dies dient im Rahmen einer Interessensabw&auml;gung &uuml;berwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. f DSGVO. </p>
                <p>Zudem verwenden wir Technologien zur Erf&uuml;llung der rechtlichen Verpflichtungen, denen wir unterliegen (z.B. um Einwilligungen in die Verarbeitung Ihrer personenbezogenen Daten nachweisen zu k&ouml;nnen) sowie zu Webanalyse und Online-Marketing. Weitere Informationen hierzu einschlie&szlig;lich der jeweiligen Rechtsgrundlage f&uuml;r die Datenverarbeitung finden Sie in den nachfolgenden Abschnitten dieser Datenschutzerkl&auml;rung. </p><p>Die Cookie-Einstellungen f&uuml;r Ihren Browser finden Sie unter den folgenden Links: 
                    <a href="https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies" >Microsoft Edge&trade;</a> 
                    / 
                    <a href="https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14" >Safari&trade;</a> 
                    / 
                    <a href="https://support.google.com/chrome/answer/95647?hl=de&amp;hlrm=en" >Chrome&trade;</a> 
                    / 
                    <a href="https://support.mozilla.org/de/products/firefox/protect-your-privacy/cookies" >Firefox&trade;</a> / <a href="https://help.opera.com/de/latest/web-preferences/#cookies" >Opera&trade;</a> 
                </p>
                <p>Soweit Sie in die Verwendung der Technologien gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben, k&ouml;nnen Sie Ihre Einwilligung jederzeit widerrufen durch eine Nachricht an die in der Datenschutzerkl&auml;rung beschriebenen Kontaktm&ouml;glichkeit. </p>
                <h2>6.&nbsp;Einsatz von Cookies und anderen Technologien zu Webanalyse und Werbezwecken</h2> 
                <p>Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, verwenden wir auf unserer Webseite die nachfolgenden Cookies und andere Technologien von Drittanbietern. Nach Zweckfortfall und Ende des Einsatzes der jeweiligen Technologie durch uns werden die in diesem Zusammenhang erhobenen Daten gel&ouml;scht. Sie k&ouml;nnen Ihre Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Weitere Informationen zu Ihren Widerrufsm&ouml;glichkeiten finden Sie in dem Abschnitt &quot;Cookies und weitere Technologien&quot;. Weitere Informationen einschlie&szlig;lich der Grundlage unserer Zusammenarbeit mit den einzelnen Anbietern finden Sie bei den einzelnen Technologien. Bei Fragen zu den Anbietern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerkl&auml;rung beschriebenen Kontaktm&ouml;glichkeit.</p> 
                <h3>&nbsp;Einsatz von Google-Diensten</h3>
                <p>Wir verwenden die nachfolgend dargestellten Technologien der Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland (&bdquo;Google&ldquo;). Die durch die Google Technologien automatisch erhobenen Informationen &uuml;ber Ihre Nutzung unserer Webseite werden in der Regel an einen Server der Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA &uuml;bertragen und dort gespeichert. F&uuml;r die USA liegt kein Angemessenheitsbeschluss der Europ&auml;ischen Kommission vor. Unsere Zusammenarbeit mit ihnen st&uuml;tzt sich auf Standarddatenschutzklauseln der Europ&auml;ischen Kommission.&nbsp; Sofern Ihre IP-Adresse &uuml;ber die Google Technologien erhoben wird, wird sie vor der Speicherung auf den Servern von Google durch die Aktivierung der IP-Anonymisierung gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google &uuml;bertragen und dort gek&uuml;rzt. Soweit bei den einzelnen Technologien nichts Abweichendes angeben ist, erfolgt die Datenverarbeitung auf Grundlage einer f&uuml;r die jeweilige Technologie geschlossenen Vereinbarung zwischen gemeinsam Verantwortlichen gem&auml;&szlig; Art. 26 DSGVO. Weitergehende Informationen &uuml;ber die Datenverarbeitung durch Google finden Sie in den 
                    <a href="https://policies.google.com/privacy?hl=de" >Datenschutzhinweisen von Google</a>
                    .
                </p> 
                <h4>&nbsp;Google Analytics</h4> 
                <p>Zum Zweck der Webseitenanalyse werden mit Google Analytics Daten (IP-Adresse, Zeitpunkt des Besuchs, Ger&auml;te- und Browser-Informationen sowie Informationen zu Ihrer Nutzung unserer Webseite) automatisch erhoben und gespeichert, aus denen unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Hierzu k&ouml;nnen Cookies eingesetzt werden. Ihre IP-Adresse wird grunds&auml;tzlich nicht mit anderen Daten von Google zusammengef&uuml;hrt. Die Datenverarbeitung erfolgt auf Grundlage einer Vereinbarung &uuml;ber die Auftragsverarbeitung durch Google.</p> 
                <h4>&nbsp;Google Maps</h4> 
                <p>Zur visuellen Darstellung von geographischen Informationen werden durch Google Maps Daten &uuml;ber Ihre Nutzung der Maps-Funktionen, insbesondere die IP-Adresse sowie Standortdaten, erhoben, an Google &uuml;bermittelt und anschlie&szlig;end von Google verarbeitet. Wir haben keinen Einfluss auf diese anschlie&szlig;ende Datenverarbeitung.</p> 
                <h4>&nbsp;Google Fonts</h4> 
                <p>Zur einheitlichen Darstellung der Inhalte auf unserer Webseite werden durch den Script Code &bdquo;Google Fonts&ldquo; Daten (IP-Adresse, Zeitpunkt des Besuchs, Ger&auml;te- und Browser-Informationen) erhoben, an Google &uuml;bermittelt und anschlie&szlig;end von Google verarbeitet. Wir haben keinen Einfluss auf diese anschlie&szlig;ende Datenverarbeitung.</p> 
                <h2>7.&nbsp;Social Media</h2> 
                <h3>&nbsp;Unsere Onlinepr&auml;senz auf Facebook</h3> 
                <p>Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO gegenüber dem jeweiligen Social Media Betreiber erteilt haben, werden bei Besuch unserer Onlinepräsenzen auf den in der oben genannten sozialen Medien Ihre Daten für Marktforschungs- und Werbezwecke automatisch erhoben und gespeichert, aus denen unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Diese können verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich Ihren Interessen entsprechen. Hierzu werden im Regelfall Cookies eingesetzt. Die detaillierten Informationen zur Verarbeitung und Nutzung der Daten durch den jeweiligen Social Media Betreiber sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, entnehmen Sie bitte den unten verlinkten Datenschutzhinweisen der Anbieter. Sollten Sie diesbezüglich dennoch Hilfe benötigen, können Sie sich an uns wenden.</p> 
                <p>
                    <a data-cke-saved-href="https://www.facebook.com/about/privacy/" href="https://www.facebook.com/about/privacy/" >
                        <strong>Facebook </strong>
                    </a>ist ein Angebot der Facebook Ireland Ltd., 4 Grand Canal Square, Dublin 2, Irland („Facebook Ireland“) Die durch Facebook Ireland automatisch erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf Facebook werden in der Regel an einen Server der Facebook, Inc., 1601 Willow Road, Menlo Park, California 94025, USA übertragen und dort gespeichert. F&uuml;r die USA liegt kein Angemessenheitsbeschluss der Europ&auml;ischen Kommission vor. Unsere Zusammenarbeit mit ihnen st&uuml;tzt sich auf Standarddatenschutzklauseln der Europ&auml;ischen Kommission.&nbsp; Die Datenverarbeitung im Rahmen des Besuchs einer Facebook Fanpage erfolgt auf Grundlage einer Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26 DSGVO. Weitere Informationen (Informationen zu Insights-Daten) finden Sie 
                    <a data-cke-saved-href="https://www.facebook.com/legal/terms/information_about_page_insights_data" href="https://www.facebook.com/legal/terms/information_about_page_insights_data" >hier</a>
                    .
                </p> 
                <h2>8.&nbsp;Kontaktmöglichkeiten und Ihre Rechte </h2> 
                <p>Als Betroffener haben Sie folgende Rechte:</p> 
                <ul> 
                    <li>gem&auml;&szlig; Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;</li> 
                    <li>gem&auml;&szlig; Art. 16 DSGVO das Recht, unverz&uuml;glich die Berichtigung unrichtiger oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li> 
                    <li>gem&auml;&szlig; Art. 17 DSGVO das Recht, die L&ouml;schung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung 
                        <ul> 
                            <li>zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information;</li> 
                            <li>zur Erf&uuml;llung einer rechtlichen Verpflichtung;</li> 
                            <li>aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder</li> 
                            <li>zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;</li> 
                        </ul> 
                    </li> 
                    <li>gem&auml;&szlig; Art. 18 DSGVO das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit 
                        <ul> 
                            <li>die Richtigkeit der Daten von Ihnen bestritten wird;</li> 
                            <li>die Verarbeitung unrechtm&auml;&szlig;ig ist, Sie aber deren L&ouml;schung ablehnen;</li>
                            <li>wir die Daten nicht mehr ben&ouml;tigen, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen oder</li> 
                            <li>Sie gem&auml;&szlig; Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li> 
                        </ul> 
                    </li> 
                    <li>gem&auml;&szlig; Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen;</li> 
                    <li>gem&auml;&szlig; Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbeh&ouml;rde zu beschweren. In der Regel k&ouml;nnen Sie sich hierf&uuml;r an die Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li> 
                </ul> 
                <p>Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Ausk&uuml;nften, Berichtigung, Einschr&auml;nkung oder L&ouml;schung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns &uuml;ber die Kontaktdaten in unserem Impressum.</p> 
                <table border="1" cellPadding="1" cellSpacing="1" style={{width: '100%'}}> 
                    <tbody> 
                        <tr> 
                            <td> 
                                <p>
                                    <strong>Widerspruchsrecht</strong>
                                </p> 
                                <p>Soweit wir zur Wahrung unserer im Rahmen einer Interessensabw&auml;gung &uuml;berwiegenden berechtigten Interessen personenbezogene Daten wie oben erl&auml;utert verarbeiten, k&ouml;nnen Sie dieser Verarbeitung mit Wirkung f&uuml;r die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, k&ouml;nnen Sie dieses Recht jederzeit wie oben beschrieben aus&uuml;ben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, zu.</p> 
                                <p>Nach Aus&uuml;bung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder wenn die Verarbeitung der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</p> 
                                <p>Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.</p> 
                            </td> 
                        </tr> 
                    </tbody> 
                </table> 
                <br/>
                <p>
                    <i>
                        <a  title="Datenschutzerklärung" href="https://legal.trustedshops.com/produkte/rechtstexter">Datenschutzerklärung</a> 
                        erstellt mit dem 
                        <a  title="Trusted Shops" href="https://legal.trustedshops.com">Trusted Shops</a> 
                        Rechtstexter in Kooperation mit 
                        <a  title="FÖHLISCH Rechtsanwälte" href="https://foehlisch.com" rel="nofollow">FÖHLISCH Rechtsanwälte</a>
                        .
                    </i>
                </p>
            </div>
            <Footer />
        </div>
    )
}
