import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
// import AboutFour from '../container/About/AboutFour';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import PictureBox from '../container/About/PictureBox';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState, useEffect} from "react";
import "../assets/scss/_HomeFade.scss";
import '../assets/scripts/cursor_mechanism.js';
// import AboutFive from '../container/About/AboutFive';

const AboutUs = () => {

    const path = 'imgs/about/FOTO';
    const matchesPC = useMediaQuery('(min-width:1200px)');
    const height = 360;

    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <React.Fragment>
            <script src="../assets/scripts/cursor_mechanism.js" type="text/javascript" ></script>
            <SEO title="Folientechnik-Design | Über" />
            { scroll<height? <Header colo="white" navcol="#fff" /> : <Header colo="black" navcol="#000" /> }
            <div align="center" style={{width: '100%', display: matchesPC? 'none':'inherit', height: '85px', position: 'absolute'}}>
                <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                    <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechnikw.png`} />
                </a>
            </div>
            <Breadcrumb 
                image={path+'0055.jpg'}
                position="center"
                size="cover"
            />
            <PictureBox
                image={'imgs/about/anatolij.jpeg'}
                headline="Anatolij Rot"
                title="Wir sind Folientechnik Design"
                text={'Hallo, ich bin Anatolij Rot, vor 20 Jahren startete ich mit Scheibentönungen. Meine Interessen zu Folien und speziell Folierungen bei Autos wurden immer stärker. Schulungen und viele Stunden Arbeit brachten mich an den Punkt, an dem ich heute bin. Aus Hobby wurde Beruf. Mich begeistert die Vielfältigkeit der Folie, sowie die Möglichkeiten und der ständige Reiz an neuen einzigartigen Folien Designs. Mittlerweile decke ich eine breite Spanne an Folierungen ab, wie Ihr hier auf der Homepage sehen könnt. Meine Erfahrung spiegelt sich deutlich im Endergebnis wider. Für technische Fragen und Beratungen stehe ich Ihnen immer gerne zur Verfügung.'}
                side='left'
                link="/contact"
                linktext="Kontakt"
                single={true}
                noTilt={true}
                sig={true}
            />
            <PictureBox
                image={path+'0075.jpg'}
                image2={path+'0154.jpg'}
                headline="Volkswagen Golf VI GTI"
                title="Voll-Folierung"
                text="Die Farbe Ihres Fahrzeugs wird langweilig oder sie wollen einfach einen neuen Look? Dabei ist eine Vollfolierung eine gute und preiswerte Alternative zu einer Lackierung. Die Folie schützt ihren alten originalen Lack. Außerdem wird die Folie als Schutzschicht betrachtet. Kratzer und Steinschläge gelangen nicht mehr an den Lack Ihres Fahrzeugs. Mit einer komplettfolierung haben Sie selbst die Möglichkeit, ein einzigartiges Design zu erschaffen. Elegant oder doch sportlich? Das liegt ganz in Ihrer Hand." 
                side='right'
                link="/voll"
                linktext="Jetzt Anfragen!"
            />
            <PictureBox
                image={path+'1769.jpg'}
                image2={path+'1761.jpg'}
                headline="Ducati Multistrada 1260"
                title="Teil-Folierung"
                text="Wenn Sie Ihr Kfz nur schützen wollen und die Farbe beibehalten wollen, können wir Ihnen eine durchsichtige Lackschutzfolie anbieten. 
                Der Preis errechnet sich durch die Größe ihres Kraftfahrzeugs, sowie durch die ausgewählte Folie. Ohne das Fahrzeug zu kennen können wir pauschal keinen Preis nennen. 
                Ihr Innenraum passt nicht mehr zum äußeren Erscheinungsbild Ihres Wagens? 
                Wir passen auf Wunsch auch den Innenraum an. Ihnen sind dabei fast keine Grenzen gesetzt." 
                side='left'
                link="/teil"
                linktext="Jetzt Anfragen!"
            />
            <TestimonialContainer classOption="bg-primary-blue" />
            <CallToActionTwo />
            <Footer s="r"/>
            <ScrollToTop />
        </React.Fragment>
    )
}

export default AboutUs;



