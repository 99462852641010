import "../../assets/scss/_HomeFade.scss";
import Tilt from 'react-parallax-tilt';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Icons = () => {

    const matchesPC = useMediaQuery('(min-width:1080px)');

    const padding = {
        padding: matchesPC? '0 4vw':''
    }

    const beschreibung = {
        width: '25%',
        padding: '0px 20px',
        fontSize: '12px'
    }

    return (
        <div style={{marginBottom: '5vw'}} className="fade-in-image">
            <div style={{display: matchesPC? 'inherit':'none'}}>
                <table>
                    <tr style={{marginBotton: '-40px'}}>

                        <td style={padding}><img className="icons" src='/imgs/icons/icon1f.png' /></td>
                        <td style={padding}><img className="icons" src='/imgs/icons/icon4f.png' /></td>
                        <td style={padding}><img className="icons" src='/imgs/icons/icon3f.png' /></td>
                        <td style={padding}><img className="icons" src='/imgs/icons/icon2f.png' /></td>

                    </tr>
                </table>
                <div style={{display: 'flex'}}>

                    <div style={beschreibung}><h1 className="titel">20 Jahre Erfahrung</h1></div>
                    <div style={beschreibung}><h1 className="titel">Zielgenau</h1></div>
                    <div style={beschreibung}><h1 className="titel">naher Kundenkontakt</h1></div>
                    <div style={beschreibung}><h1 className="titel">Zuverl&auml;ssig</h1></div>

                </div>
            </div>





            <div style={{display: matchesPC? 'none':'inherit'}}>
                <div style={{display: 'flex'}}>
                    <div style={{width: '47%'}}>
                        <div style={{width: '50%', float: 'left'}}>
                            <Tilt scale={1.1}><img src='/imgs/icons/icon1f.png' /></Tilt>
                        </div>
                        <div style={{width: '50%', float: 'right', marginTop: '10%'}}>
                            <h1 className="titel">20 Jahre Erfahrung</h1>
                        </div>
                    </div>
                    <div style={{width: '6%'}} />
                    <div style={{width: '47%'}}>
                        <div style={{width: '50%', float: 'left', marginTop: '10%'}}>
                            <h1 className="titel">naher Kundenkontakt</h1>
                        </div>
                        <div style={{width: '50%', float: 'right'}}>
                            <Tilt scale={1.1}><img src='/imgs/icons/icon3f.png' /></Tilt>
                        </div>
                    </div>
                </div>

                

                <div style={{display: 'flex'}}>
                    <div style={{width: '47%'}}>
                        <div style={{width: '50%', float: 'left'}}>
                            <Tilt scale={1.1}><img src='/imgs/icons/icon4f.png' /></Tilt>
                        </div>
                        <div style={{width: '50%', float: 'right', marginTop: '15%'}}>
                            <h1 className="titel">Zielgenau</h1>
                        </div>
                    </div>
                    <div style={{width: '6%'}} />
                    <div style={{width: '47%'}}>
                        <div style={{width: '50%', float: 'left', marginTop: '15%'}}>
                            <h1 className="titel">Zuverl&auml;ssig</h1>
                        </div>
                        <div style={{width: '50%', float: 'right'}}>
                            <Tilt scale={1.1}><img src='/imgs/icons/icon2f.png' /></Tilt>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Icons;
