import React from 'react'
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';

export default function Impressum() {

    return (
        <div>
            <Header colo="black" navcol='#000'/>
            <div align="center" className="mobile-header-custom">
                <a href="/" style={{margin: '0px auto', zIndex: '99', position: 'relative'}}>
                    <img style={{height: '85px', margin: '0px auto'}} src={`${process.env.PUBLIC_URL}/imgs/folientechniks.png`} />
                </a>
            </div>
            <img src="imgs/h1-backround-2png.png" style={{position: 'absolute', zIndex: 0}}/>
            <img src="imgs/h1-backround-1png.png" style={{position: 'absolute', zIndex: 0}}/>
            <div style={{margin: '140px auto', width: '80%', position: 'relative'}}>
                <p>
                    <strong>
                        <h1>Impressum</h1>
                    </strong>
                </p> 
                <p>
                    <strong>Folientechnik - Design</strong>
                </p> 
                <p>Anatolij Rot</p>
                <p>Flurweg 55/1,<br />88483 Burgrieden<br />Deutschland<br /> <br /> </p> 
                <p>Telefon: +49 174 3291916</p> 
                <p>Mail: info@folientechnik-design.de</p>  
                <p>Umsatzsteuer-Identifikationsnummer(n): DE54307-07530</p>
                <p>Kleinunternehmer gem&auml;&szlig; &sect; 19 UStG</p>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden </p>
                <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>
                . Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.  <br />
                <p>
                    <i>
                        <a title="Impressum" href="https://legal.trustedshops.com/produkte/rechtstexter">Impressum</a> 
                        erstellt mit dem 
                        <a title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> 
                        Rechtstexter in Kooperation mit <a title="FÖHLISCH Rechtsanwälte" href="https://foehlisch.com" rel="nofollow">FÖHLISCH Rechtsanwälte</a>
                        .
                    </i>
                </p>
            </div>
            <Footer s='r'/>
        </div>
    )
}

