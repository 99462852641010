import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function Description3() {

    const matchesTB = useMediaQuery('(max-width:1200px)');

    const textcontainer = {
        width: '90%',
        margin: '0 auto',
        backgroundColor: '#fff',
        boxShadow: '0px 5px 83px 0px rgba(9, 29, 62, 0.15)',
        marginTop: '-2vw',
        zIndex: '2',
        position: 'relative',
        color: '#000',
        padding: '2vw 4%',
    }

    const text = {
        padding: '2% 0',
        textAlign: 'left',
        listStyle: 'none',
        fontSize: matchesTB? '13px':'18px'
    }

    return (
        <React.Fragment>
            <div className="section section-padding-t90-b100 section section-padding-t90-b100">
                <div style={textcontainer}>
                    <h1 className="headline" style={{paddingBottom: '12px'}}>Preis</h1>
                    <div style={{display: 'flex'}}>
                        {/* <div style={{width: '200px'}}><img src="imgs/icons2/kosten-1.png"/></div> */}
                        <span data-aos="fade-up" style={text}>*Die Preise der Folierungen variieren stark, Laufmeter, größe des Fahrzeugs sowie Folienkosten lassen somit nicht genau bestimmen. In der Regel ist das Folieren 20-40% billiger wie ein Fahrzeug zu Lackieren. </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
